import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="使いづらさと使い方の例 - アクセシビリティ" mdxType="SEO" />
    <PageTitle title="使いづらさと使い方の例" enTitle="Examples" mdxType="PageTitle" />
    <h2 {...{
      "id": "視覚的な使いづらさ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%A6%96%E8%A6%9A%E7%9A%84%E3%81%AA%E4%BD%BF%E3%81%84%E3%81%A5%E3%82%89%E3%81%95",
        "aria-label": "視覚的な使いづらさ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`視覚的な使いづらさ`}</h2>
    <ul>
      <li parentName="ul">{`メガネを忘れた・老眼・弱視`}
        <ul parentName="li">
          <li parentName="ul">{`文字が小さく、ぼやけて見える`}</li>
          <li parentName="ul">{`文字や画像、画面全体を拡大する`}</li>
        </ul>
      </li>
      <li parentName="ul">{`眩しい外で見ている`}
        <ul parentName="li">
          <li parentName="ul">{`日光で文字が読みづらい`}</li>
          <li parentName="ul">{`画面の輝度を調整しないと読みづらい`}</li>
        </ul>
      </li>
      <li parentName="ul">{`色覚異常（色覚多様性）`}
        <ul parentName="li">
          <li parentName="ul">{`色の見え方が異なる（例：赤色が黒っぽく見え、色の区別が難しい）`}</li>
          <li parentName="ul">{`色固有の表現では正しく理解できないことがある`}</li>
        </ul>
      </li>
      <li parentName="ul">{`全盲`}
        <ul parentName="li">
          <li parentName="ul">{`視覚で情報を得ることができない`}</li>
          <li parentName="ul">{`キーボードとスクリーンリーダーで音声を読み上げながら操作する`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "聴覚的な使いづらさ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%81%B4%E8%A6%9A%E7%9A%84%E3%81%AA%E4%BD%BF%E3%81%84%E3%81%A5%E3%82%89%E3%81%95",
        "aria-label": "聴覚的な使いづらさ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`聴覚的な使いづらさ`}</h2>
    <ul>
      <li parentName="ul">{`電車の中で音声を聞きたいとき・難聴・ろう`}</li>
      <li parentName="ul">{`音を流せない・聞こえづらい・聞こえない`}</li>
      <li parentName="ul">{`動画の字幕や書き起こしを利用する`}</li>
      <li parentName="ul">{`周囲の文章から内容を推測する`}</li>
    </ul>
    <h2 {...{
      "id": "身体的な使いづらさ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%BA%AB%E4%BD%93%E7%9A%84%E3%81%AA%E4%BD%BF%E3%81%84%E3%81%A5%E3%82%89%E3%81%95",
        "aria-label": "身体的な使いづらさ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`身体的な使いづらさ`}</h2>
    <ul>
      <li parentName="ul">{`高齢で指先が震える・利き腕を骨折している・赤ちゃんを抱えている・手足が動かしにくい`}
        <ul parentName="li">
          <li parentName="ul">{`スワイプ操作やダブルタップが難しい`}</li>
          <li parentName="ul">{`小さなボタンやリンクが押しづらい`}</li>
          <li parentName="ul">{`片手で画面上のボタンが押しづらい`}</li>
          <li parentName="ul">{`入力に時間がかかる`}</li>
          <li parentName="ul">{`視線・舌などの動きや、スイッチで操作するデバイスなどを用いる`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "認知的な使いづらさ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%AA%8D%E7%9F%A5%E7%9A%84%E3%81%AA%E4%BD%BF%E3%81%84%E3%81%A5%E3%82%89%E3%81%95",
        "aria-label": "認知的な使いづらさ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`認知的な使いづらさ`}</h2>
    <ul>
      <li parentName="ul">{`子ども・注意障害`}
        <ul parentName="li">
          <li parentName="ul">{`注意力が持続しない`}</li>
          <li parentName="ul">{`周辺の動きに気が散ってしまう`}</li>
          <li parentName="ul">{`長い、難しい文章を理解できない`}</li>
        </ul>
      </li>
      <li parentName="ul">{`初めてスマホを触った人・高齢者・学習障害`}
        <ul parentName="li">
          <li parentName="ul">{`タッチ・スワイプなどの基本操作をミスする`}</li>
          <li parentName="ul">{`アイコンだけのボタンの意味がわからない`}</li>
          <li parentName="ul">{`操作中の内容を忘れてしまう`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      